import { Component } from '@angular/core';

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.css'],
})

/**
 * Display Card Component
 * @param {element} ng-content child elements that the component will contain
 * @returns Card Component
 */

export class CardComponent {}
