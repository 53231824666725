export const environment = {
	production: false,
	api: 'https://staging.unos.shockoe.dev',
	title: 'UNOS Transplant Navigator - Dev Environtment',
	cmsBaseUrl: 'https://cms.unos.shockoe.dev/wp-json/wp/v2',
	cmsAppUser: 'portal.admin',
	cmsAppKey: 'ri9i6BQwsZCPvbP1Z7dG1nyr',
	unosApiBase: 'https://reapimpatientportaltest.azure-api.net/patients/v1',
	extraHeaders: {
		'X-Authorization': 1111, // default
		'Ocp-Apim-Subscription-Key': '1b381292d16744f280f9d393b76b2504',
	},
};
