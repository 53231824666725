import { ConnectPost } from '@models/connect';
import { LearnQnA } from '@models/learn';

export const enum SEARCH_TYPE {
	CTA = 'CTA',
	CONNECTED_POST = 'CONNECTED_POST',
	LEARN_QA = 'LEARN_QA',
	DESCRIPTION = 'DESCRIPTION',
	HARDCODED = 'HARDCODED',
}
export interface HardcodedPost {
	title_en: string;
	title_es: string;
	description_en: string;
	description_es: string;
}
export type SearchItemType = {
	title: string;
	href: string;
	description: string;
	type: SEARCH_TYPE;
	external?: boolean;
	originalItem: ConnectPost | LearnQnA | HardcodedPost;
};

export type SuggestionItemType = {
	content: string;
	href: string;
	type: SEARCH_TYPE;
	external?: boolean;
	originalItem: ConnectPost | LearnQnA | HardcodedPost;
	field: string;
};
